<template>
  <div class="bg bgg">
    <div class="container immigration-top flex sb">
      <SplashText class="slogan-wrap">
        <div slot="slogan">
          <span class="slotgan-info">PHOTONET</span>
          <p class="slotgan-info">Work with Corporate Clients Anytime Anywhere</p>
        </div>
        <div slot="slogan-text">
          Smarter solutions to help manage your corporate legal cases. Provide better services to  your corporate
          clients from corporation incorporation to trademark registration.
        </div>
        <div slot="addon" class="conBtn">
          <ConsultationButton text="Request Demo" link="https://www.immigrationlaw.ai/activity/ailaw/demo" />
        </div>
      </SplashText>
      <a class="video-wrap slideLeft">
        <div class="hytPlayerWrapOuter">
          <div class="hytPlayerWrap">
            <img class="img-fluid" src="../../assets/trademark/hero_trademark.png">
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
  import SplashText from '../Block/SplashText'
  import ConsultationButton from '../Block/ConsultationButton'

  export default {
    components: { SplashText, ConsultationButton },
  }
</script>

<style scoped>
  .hytPlayerWrap {
    display: inline-block;
    position: relative;
  }

  .bg {
    background-image: url("../../assets/immigration/hero_bg2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: -4.5rem;
    padding-top: 10rem;
    height: 744px;
  }

  .immigration-top {
    color: #fff;
    padding-bottom: 5rem;
  }

  .slotgan-info {
    font-size: 2.25rem
  }

  .slogan-wrap {
    flex: 1.5;
  }

  .video-wrap {
    margin-left: 30px;
    flex: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 240px;
    width: 427px;
    max-width: 427px;
    flex-shrink: 0;
    display: block;
  }

  .slideLeft {
    animation: slideLeft 0.8s linear;
  }

  @keyframes slideLeft {
    0% {
      opacity: 0.2;
      position: relative;
      right: 100px
    }

    50% {
      opacity: 0.5;
      position: relative;
      right: 50px
    }

    100% {
      opacity: 1.0;
      position: relative;
      right: 0
    }
  }

  @media only screen and (max-width: 1100px) {
    .bg {
      background-image: url("../../assets/immigration/hero_bg2.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: -4.5rem;
      padding-top: 3.5rem;
      padding-bottom: 8rem;
    }

    .bgg {
      padding-top: 5.5rem;
      padding-bottom: 3rem;
    }


    div.immigration-top {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .conBtn {
      margin: 0 auto
    }
  }

  a.video-wrap {
    flex: none;
    width: 400px;
    height: 225px;
    margin-left: 0px;
  }

  @media (max-width: 850px) {

    span.slotgan-info,
    p.slotgan-info {
      font-size: 2rem
    }

    div.bg {
      height: auto
    }
  }

  @media (max-width: 750px) {

    span.slotgan-info,
    p.slotgan-info {
      font-size: 1.5rem
    }

    a.video-wrap {
      flex: none;
      width: 260px;
      height: 146px;
    }
  }
</style>