<template>
  <div>
    <HeroTrademark/>
    <TrademarkContent/>
  </div>
</template>

<script>
  import HeroTrademark from './Section/HeroTrademark'
  import TrademarkContent from './Section/TrademarkContent'

  export default {
    components: {HeroTrademark, TrademarkContent}
  }
</script>

<style scoped>
  .al-banner {
    margin-top: -10rem;
    margin-bottom: -45rem;

  }
</style>
